/** 百度地图自定义覆盖物 */
export default class CustomPoint extends BMapGL.Overlay {
	constructor(point, stationInfo) {
		super();
		this.point = point;
		this.stationInfo = stationInfo;
	}
	initialize(map) {
		this._map = map;
		const temp = `<div class="station_mark" style="width:136px;overflow:hidden;">
										<div class="car_number">${this.stationInfo.lineName}<br/>${this.stationInfo.txt}</div>
                    <div style="display:flex;align-items:center;justify-content:center;">
											<div class="car_icon"></div>
										</div>
                  </div>`;
		const divFragment = document.createRange().createContextualFragment(temp);
		const div = divFragment.querySelectorAll(".station_mark")[0];
		map.getPanes().markerPane.appendChild(div);
		this._div = div;
		return div;
	}
	addEventListener(event, func) {
		this._div[`on${event}`] = func;
	}
	draw() {
		// 根据地理坐标，获取图像像素坐标，并设置给容器
		const position = this._map.pointToOverlayPixel(this.point, {
			useRound: false, // 设置此参数，防止抖动
			fixPosition: true // 覆盖物跨越180时修正位置
		});
		this._div.style.left = `${position.x - (this._div.clientWidth / 2)}px`;
		this._div.style.top = `${position.y - (this._div.clientHeight)}px`;
		this._div.style.position = "absolute";
	}
}

// 基础信息
export const baseInfoMock = {
    lineNetSum: 10,
    lineSum: 6,
    stationSum: 226,
    vehicleSum: 29
};
// 车辆信息
export const carInfoMock = {
    electricSum: 9,
    gasolineSum: 8,
    hybridSum: 6,
    naturalGasSum: 6,
    offlineSum: 5,
    onlineSum: 24,
    vehicleSum: 29
};
// 用户活跃统计
export const userActiveStatisticsMock = {
    activeSum: 888,
    registeredSum: 1688,
}
// 报警类型
export const alarmTypeListMock = [
    {
        "rank": 1,
        "eventName": "司机瞌睡",
        "typeName": "DSM报警",
        "occupancy": 35,
        "timesSum": 46
    },
    {
        "rank": 2,
        "eventName": "SOC过低",
        "typeName": "车辆报警",
        "occupancy": 30,
        "timesSum": 38
    },
    {
        "rank": 3,
        "eventName": "开门行车",
        "typeName": "DSM报警",
        "occupancy": 20,
        "timesSum": 30
    },
    {
        "rank": 4,
        "eventName": "超速行驶",
        "typeName": "DSM报警",
        "occupancy": 10,
        "timesSum": 20
    },
    {
        "rank": 5,
        "eventName": "疲劳驾驶",
        "typeName": "DSM报警",
        "occupancy": 5,
        "timesSum": 15
    },
    {
        "rank": 6,
        "eventName": "醉酒驾驶",
        "typeName": "DSM报警",
        "occupancy": 1,
        "timesSum": 10
    },
    {
        "rank": 7,
        "eventName": "危险驾驶",
        "typeName": "DSM报警",
        "occupancy": 1,
        "timesSum": 8
    },
    {
        "rank": 8,
        "eventName": "不礼让行人",
        "typeName": "DSM报警",
        "occupancy": 1,
        "timesSum": 6
    }
]
// 营运总览
export const  operationalInfoMock = {
    mileageTotal: 2.68,
    passengerTotal: 25.68,
    revenueSum: 54.68,
    shiftsTotal: 1688
}
// 里程统计
export const mileageStatisticMock = [
    {
        "month": "2024-01",
        "total": 3989.40,
        "day": "2024-04-01"
    },
    {
        "month": "2024-02",
        "total": 1854.40,
        "day": "2024-04-02"
    },
    {
        "month": "2024-03",
        "total": 4868.40,
        "day": "2024-04-03"
    },
    {
        "month": "2024-04",
        "total": 2568.40,
        "day": "2024-04-04"
    },
    {
        "month": "2024-05",
        "total": 3465.40,
        "day": "2024-04-05"
    }
]
// 排版信息
export const schedulingInfoMock = {
    actualTotal: 268,
    lateTotal: 16,
    planShiftsTotal: 288,
    punctualityTotal: 242
}
// 客流统计
export const passegeFlowStatisticsMock = [
    {
        "rank": 1,
        "lineName": "202路",
        "flowTotal": 8270
    },
    {
        "rank": 2,
        "lineName": "206路",
        "flowTotal": 7568
    },
    {
        "rank": 3,
        "lineName": "208路",
        "flowTotal": 5314
    },
    {
        "rank": 4,
        "lineName": "101路",
        "flowTotal": 5021
    },
    {
        "rank": 5,
        "lineName": "快客",
        "flowTotal": 4862
    },
    {
        "rank": 6,
        "lineName": "快客2号线",
        "flowTotal": 4528
    },
    {
        "rank": 7,
        "lineName": "909路",
        "flowTotal": 4000
    },
    {
        "rank": 8,
        "lineName": "888路",
        "flowTotal": 3821
    }
]
// 线路列表
export const lineInfoListMock = [
    {
        "lineName": "202路",
        "routeId": "661de524e4b0b9a54c34e0f4",
        "startPoint": "南环路",
        "endPoint": "政务大厅",
        "vehicleNum": 8,
        "siteNum": 30
    },
    {
        "lineName": "206路",
        "routeId": "661de5fbe4b0b9a54c34e0f5",
        "startPoint": "大十字",
        "endPoint": "职业技术学院",
        "vehicleNum": 6,
        "siteNum": 35
    },
    {
        "lineName": "208路",
        "routeId": "661de621e4b0b9a54c34e0f6",
        "startPoint": "南环路",
        "endPoint": "职业技术学院",
        "vehicleNum": 8,
        "siteNum": 54
    },
    {
        "lineName": "101路",
        "routeId": "661de649e4b0b9a54c34e0f7",
        "startPoint": " 交河路口",
        "endPoint": " 实验中学",
        "vehicleNum": 7,
        "siteNum": 37
    },
    {
        "lineName": "快客1号线",
        "routeId": "661de672e4b0b9a54c34e0f8",
        "startPoint": " 南环路",
        "endPoint": " 吐鲁番北站",
        "vehicleNum": 5,
        "siteNum": 48
    },
    {
        "lineName": "快客2号线",
        "routeId": "661de695e4b0b9a54c34e0f9",
        "startPoint": " 人民银行",
        "endPoint": " 高铁站",
        "vehicleNum": 5,
        "siteNum": 22
    }
]
// 线路今日告警数据
export const selectAlarmDataMock = [
    {
        "rank": 1,
        "eventName": "急加速",
        "typeName": null,
        "occupancy": null,
        "timesSum": 48
    },
    {
        "rank": 2,
        "eventName": "急减速",
        "typeName": null,
        "occupancy": null,
        "timesSum": 36
    },
    {
        "rank": 3,
        "eventName": "开门行车",
        "typeName": null,
        "occupancy": null,
        "timesSum": 30
    },
    {
        "rank": 4,
        "eventName": "超速行驶",
        "typeName": null,
        "occupancy": null,
        "timesSum": 21
    },
    {
        "rank": 5,
        "eventName": "疲劳驾驶",
        "typeName": null,
        "occupancy": null,
        "timesSum": 15
    },
    {
        "rank": 6,
        "eventName": "不礼让行人",
        "typeName": null,
        "occupancy": null,
        "timesSum": 12
    },
    {
        "rank": 7,
        "eventName": "喝酒驾驶",
        "typeName": null,
        "occupancy": null,
        "timesSum": 10
    },
    {
        "rank": 8,
        "eventName": "不系安全带",
        "typeName": null,
        "occupancy": null,
        "timesSum": 8
    }
]
// 线路基本信息
export const selectBaseInfoMock = {
    "lineSum": null,
    "stationSum": 30,
    "lineNetSum": 10,
    "vehicleSum": 8,
    "driverSum": 7
}
// 线路排班信息
export const selectWorkInfoMock = {
    "planShiftsTotal": 288,
    "actualTotal": 268,
    "missedTotal": 20,
    "punctualityTotal": 248,
    "punctualityRate": 95.25,
    "planShiftsRate": 98.55,
    "missedRate": 10.25,
    "startShiftTime": "08:10",
    "endShiftTime": "08:40",
    "startRushTime": "20:38",
    "endRushTime": "22:39"
}
// 线路里程统计
export const selectMileageStatisticsMock = [
    {
        "month": "2024-01",
        "total": 3989.40,
        "day": "2024-04-01"
    },
    {
        "month": "2024-02",
        "total": 1854.40,
        "day": "2024-04-02"
    },
    {
        "month": "2024-03",
        "total": 4868.40,
        "day": "2024-04-03"
    },
    {
        "month": "2024-04",
        "total": 2568.40,
        "day": "2024-04-04"
    },
    {
        "month": "2024-05",
        "total": 3465.40,
        "day": "2024-04-05"
    }
]
// 线路客流统计
export const selectFlowStatisticsMock = [
    {
        "month": "2024-01",
        "total": 125,
        "day": "2024-04-01"
    },
    {
        "month": "2024-02",
        "total": 201,
        "day": "2024-04-02"
    },
    {
        "month": "2024-03",
        "total": 515,
        "day": "2024-04-03"
    },
    {
        "month": "2024-04",
        "total": 120,
        "day": "2024-04-04"
    },
    {
        "month": "2024-05",
        "total": 120,
        "day": "2024-04-05"
    }
]